<template>
  <v-container>

    <!-- Section intro -->
    <section id="intro">
      <v-layout column wrap class="my-5" align-center>
        <v-card class="mx-auto" width="90%">
          <v-card-title class="section-title">
            <h1 class="display-2 font-weight-bold mb-3">
              Livret Inclusivité
            </h1>
          </v-card-title>
          <v-card-text class="body-1">
            <p class="text--primary">
              Le Comité Inclusivité est fier de vous présenter le fruit de son travail ; un <b>livret de
              sensibilisation et d'aide à la lutte contre le harcèlement et les discriminations en milieu professionnel.</b>
              <br/> <br/>
              Ce livret de quelques pages, disponible en formats numérique et imprimable, ne se veut pas exhaustif, mais
              est pensé pour engager le dialogue autour du harcèlement et des discriminations de toutes natures, et
              fournir des ressources aux victimes, aux témoins et aux chefs·ffes d'entreprise soumis·es aux obligations
              légales, et soucieux·ses du bien-être de leurs employés·ées. Il renvoie notamment vers des ressources en
              lignes de sources fiables telles que les sites gouvernementaux dédiés.
              <br/> <br/>
              Nous l'avons pensé pour être <b>diffusé dans toutes vos entreprises</b>, et si possible au plus grand nombre, bien
              au-delà des diplômés·ées de notre École.
              <br/> <br/>
              <b>Vous pouvez le le lire, le télécharger, l'imprimer et le diffuser gratuitement.</b>
              <br/> <br/>
              Nous restons à l'écoute de vos question et vos suggestions, et vous pouvez nous contacter à tout moment via
              notre <router-link to="/contact">formulaire de contact</router-link>.
            </p>

            <p class="text--primary" align="center">
              <iframe src="//v.calameo.com/?bkcode=00745644894ff0940733c&mode=mini&clickto=embed&authid=CtnWnfJi9hEE"
                width="100%" height="800" frameborder="0" scrolling="no" allowtransparency allowfullscreen
                style="margin:0 auto;"></iframe>
            </p>

            <p class="text--primary" align="center">
              <a href="https://drive.google.com/file/d/1TIUUhpYQrNL0jP5-DEmRg-GmaF_Z2GBA/view?usp=drive_link"
                target="_blank"> Vous pouvez télécharger le livret en PDF ici.</a>
            </p>

            <p class="text--primary" align="center">
              <a href="https://drive.google.com/file/d/1znnuLyyWNS8BBrcOo2_m3RiKSNFMdi9W/view?usp=drive_link"
                target="_blank"> La version imprimable est ici.</a>
            </p>
          </v-card-text>
        </v-card>
      </v-layout>
    </section>


  </v-container>
</template>
  
<script>
export default {
  name: 'livret-inclusivite',

  data: () => ({

  }),
}
</script>
  
